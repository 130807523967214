import styled from 'styled-components'

export default styled.button`
  height: 40px;
  background: ${(props) => props.theme.jadeGreen};
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s;
  font-size: 14px;
  font-family: 'Prompt-Medium', sans-serif;
  font-weight: 500;
  line-height: 1;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &.invert {
    color: ${(props) => props.theme.jadeGreen};
    background: rgba(0, 0, 0, 0);
    border: 1px solid ${(props) => props.theme.jadeGreen};
  }
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  &:disabled {
    background: ${(props) => props.theme.greyDisabled};
    color: #fff;
    border-color: ${(props) => props.theme.greyDisabled};
    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  }
  &.loading {
    .circular-wrapper {
      width: 16px;
      height: 16px;
      position: relative;
      margin-right: 8px;
    }
    background: ${(props) => props.theme.greyDisabled};
    color: #fff;
    border-color: ${(props) => props.theme.greyDisabled};
    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  }
`
