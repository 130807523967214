import React, { memo } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/styles'

import ButtonStyled from './styledComponent'

const customCircularProgressStyle = makeStyles(() => ({
  bottom: {
    color: '#888888'
  },
  top: {
    color: '#f1f1f1',
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}))

const Button = (props) => {
  const getClassName = () => {
    let className = props.className || ' text-style'
    if (props.invert) {
      className += ' invert'
    } else if (props.loading) {
      className += ' loading'
    }
    return className
  }
  const classes = customCircularProgressStyle()
  return (
    <ButtonStyled id={props.id} className={getClassName()} onClick={props.onClick} disabled={props.disabled}>
      {props.loading && (
        <div className="circular-wrapper">
          <CircularProgress variant="determinate" className={classes.bottom} size={16} thickness={6} value={100} />
          <CircularProgress variant="indeterminate" thickness={8} size={16} className={classes.top} classes={{ circle: classes.circle }} />
        </div>
      )}
      {props.text}
    </ButtonStyled>
  )
}

Button.defaultProps = {
  invert: false,
  disabled: false
}

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  invert: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default memo(Button)
